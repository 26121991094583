<template>
  <a-dims-search-bar hide-search>
    <div class="flex justify-between p-4 space-x-2 -mx-4">
      <button @click="$router.back" class="space-x-2 text-gray-700 uppercase flex items-center">
        <i class="icon-arrowLeft text-gray-600" />
        <span class="font-bold">Atrás</span>
      </button>
    </div>
  </a-dims-search-bar>

  <div class="mt-16 text-left text-black">
    <div class="mx-4">
      <h2 class="text-black text-xl font-bold mb-2">Mis datos de facturación</h2>

      <div class="my-2">
        <p class="text-sm text-gray-400">NOMBRE</p>
        <input type="text">
      </div>
      <div class="my-2">
        <p class="text-sm text-gray-400">CIF</p>
        <input type="text">
      </div>
      <div class="my-2">
        <p class="text-sm text-gray-400">DIRECCIÓN</p>
        <input type="text">
      </div>
      <div class="flex gap-2">
        <div class="my-1">
          <input type="text">
        </div>
        <div class="my-1">
          <input type="text">
        </div>
      </div>
      <div class="flex gap-2">
        <div class="my-1">
          <input type="text">
        </div>
        <div class="my-1">
          <input type="text">
        </div>
      </div>
      <div class="my-2">
        <p class="text-sm text-gray-400">TELÉFONO</p>
        <input type="text">
      </div>
      <div class="my-2">
        <p class="text-sm text-gray-400">CORREO ELECTRÓNICO</p>
        <input type="text">
      </div>

    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRepository } from '../../../hooks'
import { useRoute } from 'vue-router'

export default {
  name: 'edit-billing',
  setup () {
    const user = ref([])
    const route = useRoute()
    const userRepository = useRepository(({ users }) => users)
    // const stripeRepository = useRepository(({ stripe }) => stripe)

    onMounted(() => {
      userRepository.show(route.params.userId)
        .then(({ data: response }) => {
          user.value = response
          console.log(user.value)
        })
    })
    return {
      user
    }
  }
}
</script>

<style scoped>
input[type="text"] {
  height: 40px !important;
}
</style>
